import UserPermissions from "@/domain/entities/user-permissions";

export default {
  state: () => ({
    auth: JSON.parse(localStorage.getItem('auth')) || null,
    organizations: JSON.parse(localStorage.getItem('organizations')) || null,
    userPermissions: UserPermissions.fromJson(JSON.parse(localStorage.getItem('userPermissions'))),
  }),

  getters: {
    authorizationIsExpired: (state) => new Date(state.auth?.expiresAt || Date.now()) <= new Date(),
    bearerToken: (state) => state.auth?.encryptedToken,
    currentOrganization: (state) => {
      if (!state.organizations) {
        return;
      }

      const organizationIndex = state.organizations.findIndex(
        ({ id }) => id === state.auth.organizationId,
      );

      return state.organizations[organizationIndex];
    },
    isAuthenticated: (_, getters) => !!getters.bearerToken,
    isLoggedIn: (_, getters) => getters.isAuthenticated && !!getters.organizationCode,
    organizationCode: (_, getters) => getters.currentOrganization?.code,
    organizationId: (_, getters) => getters.currentOrganization?.id,
    googleMapsApiKey: (_, getters) => getters.currentOrganization?.googleMapsApiKey ?? "",
    tinyMceCloudApiKey: (_, getters) => getters.currentOrganization?.tinyMceCloudApiKey ?? "",
    userCanImport: (state) => state.userPermissions?.allowedToImportUsers ?? false
  },

  actions: {
    clearAll({ commit }) {
      commit('clearAll');
      localStorage.clear();
    },

    setAuth({ commit }, auth) {
      commit('setAuth', auth);
      localStorage.setItem('auth', JSON.stringify(auth));
    },

    setOrganizations({ commit }, organizations) {
      commit('setOrganizations', organizations);
      localStorage.setItem('organizations', JSON.stringify(organizations));
    },

    setPermissions({ commit }, permissions) {
      commit('setPermissions', permissions);
      localStorage.setItem('userPermissions', JSON.stringify(permissions))
    }
  },

  mutations: {
    clearAll(state) {
      Object.keys(state).forEach((key) => {
        state[key] = null;
      });
    },

    setAuth(state, auth) {
      state.auth = auth;
    },

    setOrganizations(state, organizations) {
      state.organizations = organizations;
    },

    setPermissions(state, permissions) {
      state.userPermissions = permissions;
    }
  },

  namespaced: true,
};
