import store from '@/store';

export function isAuthenticated(_, __, next) {
  if (!store.getters['account/isAuthenticated']) {
    next({ name: 'account-login' });
  }

  next();
}

export function isLoggedIn(_, __, next) {
  if (!store.getters['account/isLoggedIn']) {
    next({ name: 'account-organisations' });
  }

  next();
}

export function isNotLoggedIn(_, __, next) {
  if (store.getters['account/isLoggedIn']) {
    next({ name: 'dashboard' });
  }

  next();
}

export function hasImportPermission(_, __, next) {
  if (store.getters['account/userCanImport']) {
    next()
    return;
  }

  next({ name: 'dashboard' })
}
