class UserPermissions {
	constructor(
		allowedToImportUsers
	) {
		this.allowedToImportUsers = allowedToImportUsers;
	}

	static fromJson(data) { 
		return new UserPermissions(
			data?.allowedToImportUsers ?? false
		); 
	}
}

export default UserPermissions;